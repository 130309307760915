import React from 'react'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'

import ContentArticle from '../atoms/ContentArticle'

const useStyles = makeStyles((theme) => ({
    figure: {
        margin: 0,
        padding: 0,

        [theme.breakpoints.up('md')]: {
            padding: `0 ${theme.spacing(4)}px`,
        },
    },
    image: {
        border: `solid 2px ${theme.palette.primary.light}`,

        [theme.breakpoints.up('md')]: {
            maxWidth: '70%',
            '.isOdd &': {
                marginLeft: 'auto',
            },
        },
    },
    figCaption: {
        position: 'relative',
        backgroundColor: theme.palette.primary.light,
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        margin: '0 auto',

        [theme.breakpoints.up('md')]: {
            padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
            marginLeft: '150px',
            '.hasImage &': {
                marginTop: '-150px',
            },
            '.isOdd &': {
                marginLeft: 'auto',
                marginRight: '150px',
            },
        },
    },
    author: {
        textAlign: 'right',
        fontStyle: 'italic',
        fontSize: theme.typography.pxToRem(16),
        marginBottom: '1rem',
    },
}))

function Testimonial({ image, text, author }) {
    const classes = useStyles()

    return (
        <ContentArticle>
            <figure
                className={classnames(
                    classes.figure,
                    image ? 'hasImage' : null
                )}
            >
                {image && (
                    <Img
                        className={classes.image}
                        fluid={image.fluid}
                        alt={'Fine Lines Construction testimonial image'}
                    />
                )}
                <figcaption className={classes.figCaption}>
                    <Typography
                        variant="body1"
                        component={'div'}
                        dangerouslySetInnerHTML={{
                            __html: text.childMarkdownRemark.html,
                        }}
                    />
                    <Typography className={classes.author}>
                        &mdash;&nbsp;{author}
                    </Typography>
                </figcaption>
            </figure>
        </ContentArticle>
    )
}

Testimonial.propTypes = {
    image: PropTypes.object,
    text: PropTypes.object.isRequired,
    author: PropTypes.string.isRequired,
}

export default Testimonial
