import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/seo'
import Layout from '../components/layout'

import ContentSection from '../components/atoms/ContentSection'
import InternalLinkButton from '../components/atoms/InternalLinkButton'
import ContentStaggeredItems from '../components/atoms/ContentStaggeredItems'
import CtaBanner from '../components/molecules/CtaBanner'
import Testimonial from '../components/molecules/Testimonial'
import PageHeader from '../components/organisms/PageHeader'

const TestimonialsPage = (data) => {
    const {
        bannerBlurb,
        headline,
        seoTitle,
        seoDescription,
        testimonials,
        ctaBanner,
    } = data.data.contentfulTestimonialPage

    return (
        <Layout>
            <SEO title={seoTitle} description={seoDescription} />
            <PageHeader
                headline={headline}
                blurb={bannerBlurb.childMarkdownRemark.html}
            />
            <ContentSection>
                <ContentStaggeredItems
                    items={testimonials}
                    itemComponent={Testimonial}
                />
            </ContentSection>
            {ctaBanner && (
                <CtaBanner
                    title={ctaBanner.bannerTitle}
                    description={ctaBanner.bannerText}
                >
                    {ctaBanner.bannerLinks &&
                        ctaBanner.bannerLinks.map((link) => {
                            return (
                                <InternalLinkButton
                                    key={link.link}
                                    link={link.link}
                                    text={link.label}
                                    variant={link.variant}
                                />
                            )
                        })}
                </CtaBanner>
            )}
        </Layout>
    )
}

export default TestimonialsPage

export const query = graphql`
    query testimonialsPageQuery {
        contentfulTestimonialPage(slug: { eq: "testimonials" }) {
            slug
            title
            headline
            bannerBlurb {
                childMarkdownRemark {
                    html
                }
            }
            testimonials {
                id
                title
                text {
                    childMarkdownRemark {
                        html
                    }
                }
                author
                image {
                    id
                    fluid {
                        ...GatsbyContentfulFluid
                    }
                }
            }
            ctaBanner {
                bannerText
                bannerTitle
                bannerLinks {
                    label
                    link
                    variant
                }
            }
            seoTitle
            seoDescription
        }
    }
`
